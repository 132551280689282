// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTsdNavbar from "../common/navbar/DashboardTsdNavbar.res.js";
import * as DashboardTsdNewForm from "./components/DashboardTsdNewForm.res.js";

function DashboardTsdNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardTsdNavbar.make, {
                      currentLink: "New"
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsx(DashboardTsdNewForm.make, {})
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var $$default = DashboardTsdNew$default;

export {
  Css ,
  $$default as default,
}
/* Container Not a pure module */
